import React from 'react';
import {useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import firebase from 'firebase/compat/app';
import reportWebVitals from './reportWebVitals';
import "firebase/compat/database";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { randomInt } from 'crypto';

var number = 0;
function Index(){
  return(
    <BrowserRouter>
      <Routes>
        <Route path="/" >
          <Route index element={<Header />} />
          <Route path='admin' element={<Admin />} />
          <Route path='*' element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Index />
);

let sms12 = "body{padding: 0px; background: black;}";

function Login({onLogin}){
  function loginSubmit(){
    let val = document.getElementById("sifreValue").value;
    if(val == "sasselmnr1234"){
      alert("Basarili");
    }
    else{
      alert("Basarisiz");
    }
  }
  return (
    <div style={{}}>
      <style>
        {sms12}
      </style>
      <center>
        <div style={{borderRadius: "10px", paddingLeft: "10px", paddingRight: "10px", paddingTop: "20px",paddingBottom: "20px",  boxShadow: "0 4px 2px 0 (0,0,0,0.5)", maxWidth: "500px",minWidth:"400px", height: "200px", marginTop: "22.5vh",   background: "white"}}>
          <p style={{fontSize: "25px", fontFamily:"'Poppins',sans-serif", marginTop: "30px", fontWeight: "bold", textAlign: "center"}}>
            Şifreleme
          </p>
          <input id='sifreValue' placeholder='Şifreyi yaz...' style={{width: "calc(90% - 20px)", borderRadius: "4px", fontFamily: "\"Poppins\", sans-serif", border: "1px solid #727275", paddingLeft: "10px", paddingRight: "10px", paddingTop: "5px", paddingBottom :"5px"}} />
          <button onClick={() =>{
            onLogin(document.getElementById("sifreValue").value)
          }} style={{width: "90%", marginTop: "20px", background: "#006994", fontWeight: "bold",paddingTop: "5px", paddingBottom:"5px",border: "none", color: "white", fontFamily: "'Poppins', sans-serif"}}>Tamam</button>
        </div>
      </center>
    </div>
  );
}
function Admin(){
  const firebaseConfig = {
    apiKey: "AIzaSyBlNkGxC1mHA4VPfjkzdrJB1zk38c8PlxU",
    authDomain: "bionluk1-29b61.firebaseapp.com",
    databaseURL: "https://bionluk1-29b61-default-rtdb.firebaseio.com",
    projectId: "bionluk1-29b61",
    storageBucket: "bionluk1-29b61.appspot.com",
    messagingSenderId: "233483656795",
    appId: "1:233483656795:web:af41eb019615e22713297e"
  };
  firebase.initializeApp(firebaseConfig);

  let [data, setData] = useState([]);
  let [section, setSection] = useState([]);
  let [bolum, setBolum] = useState(0);
  let [input1, setInput1] = useState("");
  let [input2, setInput2] = useState("");
  let [input3, setInput3] = useState("");
  let [input4, setInput4] = useState("");
  let [login, setLogin] = useState("");

  function checkLogin(value){
    if(value == "sasselmnr1234"){
      alert("Basarili");
      setLogin("true");
    }
    else{
      alert("Basarisiz");
    }
  }

  useEffect(() =>{
    firebase.database().ref("Sections").on("value", function(snapshot){
      let value = [];
      snapshot.forEach(function(childSnapshot){
        let parent = childSnapshot.val();
        value = [...value, parent];
      });
      setSection(value);
    });
    firebase.database().ref("Datas").on("value", function(snapshot){
      let value = [];
      snapshot.forEach(function(childSnapshot){
        let parent = childSnapshot;
        let cumle = parent.child("cumle").val();
        let kalib = parent.child("kalib").val();
        let kelime = parent.child("kelime").val();
        let bolum = parent.child("bolum").val();
        let object = {"cumle": cumle, "kalib": kalib, "kelime": kelime, "bolum": bolum};
        value = [...value, object];
      });
      setData(value);
    });
  }, []);

  function kelimelerClick(){
    setBolum(0);
  }
  function bolumlerClick(){
    setBolum(1);
  }

  //Handle change for inputs:
  function input1Handle(event){
    setInput1(event.target.value);
  }
  function input2Handle(event){
    setInput2(event.target.value);
  }
  function input3Handle(event){
    setInput3(event.target.value);
  }
  function input4Handle(event){
    setInput4(event.target.value);
    event.focus();
  }

  //Sil buttons:
  function table1Sil(kelime, bolum){
    firebase.database().ref("Datas").once("value", function(snapshot){
      snapshot.forEach(function(childSnapshot){
        if(childSnapshot.child("kelime").val() == kelime && childSnapshot.child("bolum").val() == bolum){
          firebase.database().ref("Datas").child(childSnapshot.key).remove().then(function(next){
            setInput4("111");
          });
        }
      });
    });
  }
  function table2Sil(val){
    firebase.database().ref("Sections").once("value", function(snapshot){
      snapshot.forEach(function(childSnapshot){
        if(childSnapshot.val() == val){
          firebase.database().ref("Sections").child(childSnapshot.key).remove().then(function(next){
            setInput4("111");
          });
        }
      });
    });
  }

  //Ekle buttons:
  function table2Ekle(){
    let val = document.getElementById("input4").value;
    firebase.database().ref("Sections").push(val);
    setInput4("111");
  }
  function table1Ekle(){
    let kelime = document.getElementById("input1").value;
    let cumle = document.getElementById("input2").value;
    let kalip = document.getElementById("input3").value;
    let select1 = document.getElementById("select1").value;

    firebase.database().ref("Sections").once("value", function(snapshot){
      snapshot.forEach(function(childSnapshot){
        if(childSnapshot.val() == select1){
          var key = childSnapshot.key;
          firebase.database().ref("Datas").push({
            bolum: key,
            kelime: kelime,
            cumle: cumle,
            kalib: kalip
          }).then(function(next){
            setInput4("111");
          });
        }
      });
    });
  }
  function BolumShow({val}){
    let vv1 = "";
    firebase.database().ref("Sections").once("value", function(snapshot){
      let val1 = snapshot.child(val).val();
      vv1 = val1;
      
    });
    if(vv1 != ""){
      return(
        <td>{vv1}</td>
      );
    }
  }

  function ShowOne(){
    let number1 = 0;
    if(bolum == 0){
      return (
        <table style={{width: "100%", marginTop: 20}}>
          <tr>
            <td style={{fontWeight:"bold"}}>
              Kelime:
            </td>
            <td style={{fontWeight:"bold"}}>
              Cümle:
            </td>
            <td style={{fontWeight:"bold"}}>
              Kalıp:
            </td>
            <td style={{fontWeight:"bold"}}>
              Ekle:
            </td>
            <td style={{fontWeight:"bold"}}>
              Sil:
            </td>
            <td style={{fontWeight:"bold"}}>
              Bölüm:
            </td>
          </tr>
          <tr>
            <td>
              <input id='input1' type="text" style={{width: "80%"}} placeholder="Kelimeyi yazın" />
            </td>
            <td>
              <input id='input2' type="text" style={{width: "80%"}} placeholder="Cümleyi yazın" />
            </td>
            <td>
              <input id='input3' type="text" style={{width: "80%"}} placeholder="Kalıbı yazın" />
            </td>
            <td>
              <button onClick={table1Ekle}>Ekle</button>
            </td>
            <td>

            </td>
            <td>
              <select id='select1'>
                {section.map((item)=>(
                  <option>{item}</option>
                ))}
              </select>
            </td>
          </tr>
          {data.map((item)=>(
            <tr>
              <td>{item.kelime}</td>
              <td>{item.cumle}</td>
              <td>
                {item.kalib}
              </td>
              <td>
                <button>Ekle</button>
              </td>
              <td>
                <button onClick={()=> table1Sil(item.kelime, item.bolum)}>Sil</button>
              </td>
              {
                <BolumShow val={item.bolum}/>
              }
            </tr>
          ))}
        </table>
      );
      
    }
    else{
      return (
        <table style={{width: "100%", marginTop: 20}}>
          <tr>
            <td style={{fontWeight:"bold"}}>
              Numara:
            </td>
            <td style={{fontWeight:"bold"}}>
              Bölüm İsmi:
            </td>
            <td style={{fontWeight:"bold"}}>
              Ekle:
            </td>
            <td style={{fontWeight:"bold"}}>
              Sil:
            </td>
          </tr>
          <tr>
            <td>
              Numara
              </td>
            <td>
              <input id='input4'  type="text" style={{width: "80%"}} placeholder="Bölüm ismini yazın" />
            </td>
            <td>
              <button onClick={table2Ekle}>Ekle</button>
            </td>
          </tr>
          {section.map((item, index)=>(
            <tr>
              <td>{index+1}</td>
              <td>{item}</td>
              <td>
                <button>Ekle</button>
              </td>
              <td>
                <button onClick={() => table2Sil(item)} >Sil</button>
              </td>
            </tr>
          ))}
        </table>
      );
    }
  }
  function Ms(){
    return(
      <center>
        <div style={{marginTop: 30}}>
          <center style={{justifyContent:"center"}}>
            <button onClick={kelimelerClick} style={{width: "120px", cursor:"pointer"}}>
                Kelimeler
            </button>
            <button onClick={bolumlerClick} style={{width: "120px", marginLeft: 20, cursor: "pointer"}}>
                Bölümler
            </button>
          </center>
        </div>
        <div style={{width: "80%"}}>
          {
            <ShowOne />
          }
          
        </div>
      </center>
    );
  }
  return(
    <React.Fragment>
      {login == "true" ? <Ms /> : <Login onLogin={checkLogin} />}
    </React.Fragment>
  );
}
function NoPage(){
  return(
    <h1>Böyle bir sayfa yok!</h1>
  );
}
function Header(){
  const [sections, setSections] = useState([]);
  const [data, setData] = useState([]);
  const [random, setRandom] = useState(0);
  const [indiki, setIndiki] = useState([]);
  let [randomData, setRandomData] = useState(0);
  let [randomData2, setRandomData2] = useState(0);
  let [randomData3, setRandomData3] = useState(0);

  useEffect(()=>{
    firebase.database().ref("Sections").once("value", function(snapshot){
      snapshot.forEach(function(childSnapshot){
        let value = childSnapshot.val();
        if(value == indiki[1]){
          let key = childSnapshot.key;
          let value3 = [];
          console.log(key);
          firebase.database().ref("Datas").orderByChild("bolum").equalTo(key).once("value", function(snapshot2){
            snapshot2.forEach(function(childSnapshot){
              let cumle = childSnapshot.child("cumle").val();
              let kalib = childSnapshot.child("kalib").val();
              let kelime = childSnapshot.child("kelime").val();
              let bolum = childSnapshot.child("bolum").val();
              console.log(kelime);
              let value2 = {"cumle": cumle, "kalib": kalib, "kelime": kelime};
              value3 = [...value3, value2];
            });
            setData(value3);
          });
        }
      });
    });
  }, [indiki]);
  useEffect(() => {
    firebase.database().ref("Sections").once("value", function(snapshot){
      let value = [];
      snapshot.forEach(function(childSnapshot){
        let v1 = childSnapshot.val();
        value = [...value, v1];
      })
      setSections(value);
    });
    firebase.database().ref("Datas").once("value", function(snapshot){
      let value = [];
      snapshot.forEach(function(childSnapshot){
        let cumle = childSnapshot.child("cumle").val();
        let kalib = childSnapshot.child("kalib").val();
        let kelime = childSnapshot.child("kelime").val();
        let bolum = childSnapshot.child("bolum").val();
        if(indikiBolum != "" && bolum != indikiBolum){
          
        }
        else{
          let value2 = {"cumle": cumle, "kalib": kalib, "kelime": kelime};
          value = [...value, value2];
        }
      });
      setData(value);
    });
  }, []);
  //Config:
  const firebaseConfig = {
    apiKey: "AIzaSyBlNkGxC1mHA4VPfjkzdrJB1zk38c8PlxU",
    authDomain: "bionluk1-29b61.firebaseapp.com",
    databaseURL: "https://bionluk1-29b61-default-rtdb.firebaseio.com",
    projectId: "bionluk1-29b61",
    storageBucket: "bionluk1-29b61.appspot.com",
    messagingSenderId: "233483656795",
    appId: "1:233483656795:web:af41eb019615e22713297e"
  };
  firebase.initializeApp(firebaseConfig);

  if(data.length > 0){
    randomData = Math.floor((Math.random() * data.length)+1);
    randomData -= 1;
    randomData2 = Math.floor((Math.random() * data.length)+1);
    randomData2 -= 1;
    randomData3 = Math.floor((Math.random() * data.length)+1);
    randomData3 -= 1;
  }

  var indikiBolum = "";

  function itemClick(value){
    setIndiki([value + randomIntFromInterval(0,1000), value]);
  }
  function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  function yeniicerikolustur(){
    setRandomData(parseInt(Math.floor(Math.random() *1000)));
  }
  console.log(data);
  console.log(sections.length);
  return(
    <React.Fragment>
      <header>
        <nav style={{width: "100%", display: "flex",  height: "50px", background: "#1abc9c", justifyContent: "center"}}>
          {sections.map((item)=>(
            <p key={item} onClick={() => itemClick(item)} className='menuLink'>{item}</p>
          ))}
        </nav>
      </header>
      <main>
        <center>
          <div style={{maxWidth: "1140px", paddingTop:"1rem", paddingBottom:"1rem", paddingLeft: "0.5rem", paddingRight:"0.5rem", marginTop:"5vh", border:"1px solid black", borderColor:"rgba(196,196,196,196)", borderRadius:"0.5rem"}}>
            <p style={{fontFamily:"'Poppins', sans-serif", fontWeight: "bold", fontSize:"25px"}}>Sizin için hazırladığımız cümle, kelime ve kalıp ifade </p>
            <p style={{fontFamily:"'Poppins', sans-serif", fontWeight: "400", fontSize: "20px", marginBottom: "5px"}}>Kelime:</p>
            <span style={{fontFamily:"'Poppins', sans-serif",color: "white", background:"#1abc9c", fontWeight:"550",  width: "auto", paddingLeft:20, paddingRight: 20, borderRadius:"100px", paddingTop: 5, paddingBottom:5, fontSize: "18px"}}>
              {data.length > 0 ? data[randomData].kelime : ""}
            </span>
            <p style={{fontFamily:"'Poppins', sans-serif", fontWeight: "400", fontSize: "20px", marginBottom: "5px"}}>Cümle:</p>
            <span style={{fontFamily:"'Poppins', sans-serif",color: "white", background:"#1abc9c", fontWeight:"550",  width: "auto", paddingLeft:20, paddingRight: 20, borderRadius:"100px", paddingTop: 5, paddingBottom:5, fontSize: "18px"}}>
              {data.length >0 ? data[randomData2].cumle : ""}
            </span>
            <p style={{fontFamily:"'Poppins', sans-serif", fontWeight: "400", fontSize: "20px", marginBottom: "5px"}}>Kalıp:</p>
            <span style={{fontFamily:"'Poppins', sans-serif",color: "white", background:"#1abc9c", fontWeight:"550",  width: "auto", paddingLeft:20, paddingRight: 20, borderRadius:"100px", paddingTop: 5, paddingBottom:5, fontSize: "18px"}}>
              {data.length > 0 ? data[randomData3].kalib : ""}
            </span>
          </div>
          <button onClick={yeniicerikolustur} style={{fontFamily:"'Poppins', sans-serif",color: "white", background:"#1abc9c", fontWeight:"550",  width: "auto", paddingLeft:20, paddingRight: 20,border: "none", marginTop:"20px", height: "50px", borderRadius:"100px", paddingTop: 5, width:"380px", paddingBottom:5, fontSize: "18px"}}>
            Yeni İçerik Oluştur
          </button>
        </center>
      </main>
    </React.Fragment>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
